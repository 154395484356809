import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import NotFound from '../pages/NotFound';
import Login from '../pages/Login';
import Home from '../pages/Home';
import { HOME, LOGIN } from '../constant/routes';
import { useDispatch, useSelector } from 'react-redux';
import auth from '../services/auth';
import {
  logoutUser,
  resetUser,
  setLoginError,
  setLoginStatus,
  setUser,
} from '../slices/userSlice';
import { SPECIALIST_USER_ROLE, STATUS } from '../constant';
import { requestUser } from '../api/user';
import idleTimer from '../services/IdleTimer';

const { SUCCESSFUL, FAILED, DEFAULT } = STATUS;

const RootRoutes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { loginStatus, displayName, role } = useSelector((state) => state.user);

  const navigateToLoginIfNeeded = useCallback(() => {
    if (location.pathname !== LOGIN) {
      return history.push(LOGIN);
    }
  }, [history, location.pathname]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        dispatch(resetUser());
        return navigateToLoginIfNeeded();
      } else {
        const token = await user.getIdToken();
        localStorage.setItem('token', token);
        if ([DEFAULT, FAILED].includes(loginStatus)) {
          if (displayName && role === SPECIALIST_USER_ROLE) {
            return dispatch(setLoginStatus(SUCCESSFUL));
          }

          const { status: userStatus, data: userData } = await requestUser(
            user.uid
          );

          if (
            userStatus !== SUCCESSFUL ||
            userData.role !== SPECIALIST_USER_ROLE
          ) {
            dispatch(setLoginStatus(FAILED));
            dispatch(
              setLoginError({
                message:
                  userStatus === SUCCESSFUL
                    ? 'Sorry, the account is invalid, please contact Telecare to get your account details.'
                    : 'Oops, Something went wrong, please try again later.',
              })
            );
            return auth.signOut();
          }

          dispatch(setUser(userData));
          dispatch(setLoginError(null));
          idleTimer.startInterval(() => {
            dispatch(logoutUser());
            auth.signOut();
          });
          return dispatch(setLoginStatus(SUCCESSFUL));
        }
      }
    });
  }, []);

  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken();
          localStorage.setItem('token', token);
        } catch (error) {
          console.error({
            event: 'getIdTokenFromFirebaseAuthFailed',
            error: error.message,
          });
          localStorage.removeItem('token');
        }
      } else {
        localStorage.removeItem('token');
      }
    });
  }, []);

  return (
    <Switch>
      <Route exact path={LOGIN}>
        <Login />
      </Route>

      <Route path={HOME}>
        <Home />
      </Route>

      <Route component={NotFound} />
    </Switch>
  );
};
export default RootRoutes;
