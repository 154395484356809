import { initializeApp } from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_ID,
};

class Firebase {
  app;

  constructor() {
    this.app = initializeApp(config);
  }
}

const firebase = new Firebase();
export default firebase;
