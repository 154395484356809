import base64 from 'base-64';
import { StatusCodes } from 'http-status-codes';
import request from '../../services/request';
import { STATUS } from '../../constant';

export const getWorkflow = async (workflowId) => {
  const { data } = await request.get(`/service/workflow/${workflowId}`);
  return { data };
};

export const login = async ({ workflowId, password }) => {
  try {
    const pwd = base64.encode(`${workflowId}:${password}`);
    const { status } = await request.post(
      '/service/workflow/verify',
      {},
      {
        responseType: 'json',
        headers: {
          Authorization: `Basic ${pwd}`,
        },
      }
    );

    if (status === StatusCodes.OK) {
      return { status: STATUS.SUCCESSFUL };
    }

    return { error: 'Verify Failed', status: STATUS.FAILED };
  } catch (error) {
    return { error, status: STATUS.FAILED };
  }
};

export const updateWorkflow = async (workflowId, workflow, acceptance) => {
  try {
    const { status } = await request.put(`/service/workflow/${workflowId}`, {
      acceptance,
      notes: workflow.notes,
    });

    if (status === StatusCodes.NO_CONTENT) {
      return {
        status: STATUS.SUCCESSFUL,
      };
    }

    return {
      status: STATUS.FAILED,
      error: 'Can not update workflow',
    };
  } catch (error) {
    return {
      status: STATUS.FAILED,
      error,
    };
  }
};
