import moment from 'moment';

const EXPIRED_TIME_KEY = '_expiredTimestamp';
const getExpiredTimestamp = () => {
  const expiredTimeStr = localStorage.getItem(EXPIRED_TIME_KEY) || '0';
  return parseInt(expiredTimeStr);
};

class IdleTimer {
  timeout: number;
  onTimeout: () => void;
  interval: number | null;
  timeoutTracker: number | null;

  constructor({ timeout }: { timeout: number }) {
    this.timeout = timeout;

    const expiredTimestamp = getExpiredTimestamp();
    if (expiredTimestamp > 0 && moment(expiredTimestamp).isBefore(moment())) {
      this.onTimeout && this.onTimeout();
      return;
    }
  }

  startInterval = (onTimeout: () => void) => {
    this.onTimeout = onTimeout;
    this.updateExpiredTime();
    this.setupTracker();

    this.interval = window.setInterval(() => {
      if (moment().isAfter(moment(getExpiredTimestamp()))) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 30000);
  };

  updateExpiredTime = () => {
    if (this.timeoutTracker) {
      window.clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = window.setTimeout(() => {
      localStorage.setItem(
        EXPIRED_TIME_KEY,
        String(moment().add(this.timeout, 'minutes').valueOf())
      );
    }, 3000);
  };

  setupTracker = () => {
    window.addEventListener('mousemove', this.updateExpiredTime);
    window.addEventListener('scroll', this.updateExpiredTime);
    window.addEventListener('keydown', this.updateExpiredTime);
    window.addEventListener('click', this.updateExpiredTime);
  };

  cleanUp = () => {
    localStorage.removeItem(EXPIRED_TIME_KEY);
    window.clearInterval(this.interval);
    window.clearTimeout(this.timeoutTracker);
    window.removeEventListener('mousemove', this.updateExpiredTime);
    window.removeEventListener('scroll', this.updateExpiredTime);
    window.removeEventListener('keydown', this.updateExpiredTime);
    window.removeEventListener('click', this.updateExpiredTime);
  };
}

// Please change timeout duration here
export default new IdleTimer({ timeout: 60 });
