import { combineReducers } from '@reduxjs/toolkit';
import appReducer from '../slices/appSlice';
import workflowReducer from '../slices/worflowSlice';
import userReducer from '../slices/userSlice';

const rootReducer = combineReducers({
  app: appReducer,
  workflow: workflowReducer,
  user: userReducer,
});

export default rootReducer;
