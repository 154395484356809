import { Descriptions, Spin, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  AUS_STANDARD_DATE,
  DATE_WITH_DASH,
  STANDARD_ISO,
  WEEK_TIME,
} from '../../../constant/time-format';
import AddTodoAction from './AddTodoAction';
import DownloadButton from '../../../components/DownloadButton';
import DismissAction from './DismissAction';
import { InfoCircleOutlined } from '@ant-design/icons';
import useQuery from '../../../hooks/useQuery';
import { WORKFLOW } from '../../../constant/apis';
import { STATUS } from '../../../constant';

const ActionGroup = styled.div`
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
  padding: 0 10%;
`;
const AppointmentInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  gap: 10px;
`;
const TimeLabel = styled.div`
  border-radius: 21px;
  font-weight: bold;
  border: 1px solid;
  padding: 8px;
  width: 60%;
  text-align: center;
`;

const getLatestAppointmentTime = (data) => {
  const apptTimes = data
    .filter((clientAppointment) => clientAppointment.appointment != null)
    .map((clientAppointment) =>
      moment(clientAppointment.appointment.startDateTime, STANDARD_ISO)
    )
    .filter((startTime) => startTime.isAfter(moment()));
  return apptTimes.length > 0 ? moment.min(apptTimes) : null;
};

const DetailsCard = ({
  selectedDoc,
  fileURL,
  refetchPractitionerMedicalResults,
  practitionerId,
}) => {
  const { clientName, clientDob, createdDate } = selectedDoc;
  const isClientExist = clientName || clientDob;
  const dateOfBirth = `${moment(clientDob, AUS_STANDARD_DATE).format(
    DATE_WITH_DASH
  )}`;
  const { data, status } = useQuery(
    `${WORKFLOW}?fullName=${clientName}&dateOfBirth=${dateOfBirth}&practitionerId=${practitionerId}`,
    clientName && clientDob
  );
  const latestAppointment = data && getLatestAppointmentTime(data);
  return (
    <>
      <Descriptions
        title={
          <div>
            <InfoCircleOutlined /> DETAILS
          </div>
        }
        column={2}
        layout="vertical"
        labelStyle={{ fontSize: '10px', fontWeight: 'bold', color: 'gray' }}
      >
        {clientName && (
          <Descriptions.Item label="PATIENT NAME">
            {clientName}
          </Descriptions.Item>
        )}
        {clientDob && (
          <Descriptions.Item label="DATE OF BIRTH">
            {clientDob}
          </Descriptions.Item>
        )}
        {createdDate && (
          <Descriptions.Item label="RECEIVED DATE">
            {moment.unix(createdDate).format(AUS_STANDARD_DATE)}
          </Descriptions.Item>
        )}
      </Descriptions>
      {!isClientExist && (
        <Typography.Text>No Patient Information Recognized</Typography.Text>
      )}
      <ActionGroup>
        {isClientExist && <AddTodoAction selectedDoc={selectedDoc} />}
        <DownloadButton
          fileURL={fileURL}
          fileName={
            clientName && clientDob && createdDate
              ? `${clientName}_${clientDob}_${moment
                  .unix(createdDate)
                  .format(AUS_STANDARD_DATE)}`
              : `test_result_${moment
                  .unix(createdDate)
                  .format(AUS_STANDARD_DATE)}`
          }
        />
        <DismissAction
          selectedDocId={selectedDoc?.id}
          refetchPractitionerMedicalResults={refetchPractitionerMedicalResults}
        />
      </ActionGroup>
      <AppointmentInfo>
        {status === STATUS.LOADING ? (
          <Spin />
        ) : latestAppointment ? (
          <>
            <div>Next appointment for this patient</div>
            <TimeLabel>{latestAppointment.format(WEEK_TIME)}</TimeLabel>
            <div>{latestAppointment.format(AUS_STANDARD_DATE)}</div>
          </>
        ) : (
          <div>No next appointment for this patient.</div>
        )}
      </AppointmentInfo>
    </>
  );
};
export default DetailsCard;
