import { Document, Page } from 'react-pdf';
import { Alert, Spin } from 'antd';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { getFileTypeByFileExtension } from '../../pages/Home/tools';
import { FILE_TYPES, STATUS } from '../../constant';

const { FAILED, LOADING, DEFAULT, SUCCESSFUL } = STATUS;

const PDFDocumentWrapper = styled.div`
  canvas {
    margin: auto;
    width: auto !important;
    height: auto !important;
  }
  height: calc(100vh - 130px);
  overflow-y: scroll;
`;

const Image = ({ fileURL }) => {
  const [fileStatus, setFileStatus] = useState(DEFAULT);

  useEffect(() => {
    if (fileURL) {
      setFileStatus(LOADING);
    }
  }, [fileURL]);

  if (fileStatus === FAILED) {
    return <Alert type="error" message="Fetch file from server failed" />;
  }

  return (
    <>
      {fileStatus === LOADING && <Spin tip="Loading..." />}
      <img
        src={fileURL}
        alt="file previewer"
        onError={() => setFileStatus(FAILED)}
        onLoad={() => {
          setFileStatus(SUCCESSFUL);
        }}
        style={{ display: fileStatus !== SUCCESSFUL ? 'none' : 'inline-block' }}
      />
    </>
  );
};

const FilePreviewer = ({ fileURL }) => {
  const [numPages, setNumPages] = useState(0);

  const fileType = getFileTypeByFileExtension(fileURL);

  const onDocumentLoaded = ({ numPages }) => {
    setNumPages(numPages);
  };

  const _buildPages = () => {
    const pages = Array.from(Array(numPages), (_, i) => i + 1);
    return pages.map((pageNumber) => (
      <Page pageNumber={pageNumber} key={pageNumber} renderTextLayer={false} />
    ));
  };

  if (!fileURL) {
    return <Alert type="error" message="File URL does not exist." />;
  }

  if (!fileType) {
    return <Alert type="error" message="File type is now allowed." />;
  }

  return (
    <PDFDocumentWrapper>
      {fileType === FILE_TYPES.DOC ? (
        <Document file={fileURL} onLoadSuccess={onDocumentLoaded}>
          {_buildPages()}
        </Document>
      ) : (
        <Image fileURL={fileURL} />
      )}
    </PDFDocumentWrapper>
  );
};

export default FilePreviewer;
