import request from '../../services/request';
import { TODO } from '../../constant/apis';
import { StatusCodes } from 'http-status-codes';
import { STATUS } from '../../constant';
import { ITodo } from '../../types/ITodo';

export const createTodo = async (todo: ITodo) => {
  try {
    const { data, status } = await request.post(TODO, todo);

    if (status === StatusCodes.CREATED && data) {
      return {
        status: STATUS.SUCCESSFUL,
        data,
      };
    }

    return {
      status: STATUS.FAILED,
      error: 'Create todo failed',
    };
  } catch (error) {
    console.error({
      event: 'createTodoFailed',
      ...error,
    });

    return {
      status: STATUS.FAILED,
      error: error.message ?? 'Create todo failed',
    };
  }
};
