import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Spin, Alert, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

import DoctorFeedback from './DoctorFeedback';
import FilePreviewer from '../../components/FilePreviewer';
import { fetchWorkflow } from '../../slices/worflowSlice';
import AuthenticationForm from './AuthenticationForm';
import { STATUS } from '../../constant';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const SpecialistConfirmation = () => {
  const dispatch = useDispatch();
  const { workflowId } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const workflow = useSelector((state) => state.workflow);
  let fileId = workflow?.data?.referralLetter?.id || '';

  if (
    fileId.toLowerCase().endsWith('.docx') ||
    fileId.toLowerCase().endsWith('.rtf') ||
    fileId.toLowerCase().endsWith('.doc')
  ) {
    fileId =
      fileId.substring(
        0,
        fileId.length - fileId.split('.')[fileId.split('.').length - 1].length
      ) + 'pdf';
  }

  const updateAuthenticationResult = useCallback((isAuthenticated) => {
    setIsAuthenticated(isAuthenticated);
  }, []);

  useEffect(() => {
    dispatch(fetchWorkflow(workflowId));
  }, [dispatch, workflowId]);

  const renderReferralLetterPDFSection = () => {
    if (fileId) {
      return (
        <FilePreviewer
          title="Referral Letter PDF"
          fileURL={`${process.env.REACT_APP_CLOUD_FUNCTIONS}/referral-letters/${fileId}`}
        />
      );
    }

    if (workflow.status === STATUS.LOADING) {
      return <Spin />;
    }

    if (workflow.status === STATUS.FAILED) {
      return <Alert message="Can not get referral letter" type="error" />;
    }

    return null;
  };

  return isAuthenticated ? (
    <Row>
      <Col
        span={10}
        style={{
          boxShadow:
            'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
        }}
      >
        <DoctorFeedback />
      </Col>
      <Col span={14}>
        <Typography.Title style={{ textAlign: 'center' }} level={5}>
          Referral Letter
        </Typography.Title>
        {renderReferralLetterPDFSection()}
      </Col>
    </Row>
  ) : (
    <AuthenticationForm
      updateAuthenticationResult={updateAuthenticationResult}
    />
  );
};

export default SpecialistConfirmation;
