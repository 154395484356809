import { StatusCodes } from 'http-status-codes';
import request from '../../services/request';
import { STATUS } from '../../constant';

export const requestUser = async (uid) => {
  try {
    const { data, status } = await request.get(`/admin/auth/user/${uid}`);
    if (status === StatusCodes.OK && data) {
      return {
        status: STATUS.SUCCESSFUL,
        data,
      };
    }

    return {
      status: STATUS.FAILED,
      error: 'Can not get user info',
    };
  } catch (error) {
    return {
      status: STATUS.FAILED,
      error,
    };
  }
};
