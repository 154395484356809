import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Badge, Tabs, Typography } from 'antd';
import IPractitionerMedicalDocument from '../../../types/IPractitionerMedicalDocument';
import { DOC_TYPE } from '../../../constant';
import { Documents } from './Documents';

const { TabPane } = Tabs;

const DocumentList: FC<{
  documentList: IPractitionerMedicalDocument[];
  selectedDocId: null | string;
  setSelectedDocId: Dispatch<SetStateAction<string>>;
  refetchPractitionerMedicalResults: () => void;
}> = ({
  documentList,
  selectedDocId,
  setSelectedDocId,
  refetchPractitionerMedicalResults,
}) => {
  const [activeKey, setActiveKey] = useState<string>(DOC_TYPE.LINKED);

  const { linkedDocs, unLinkedDocs } = useMemo(
    () =>
      documentList.reduce(
        (result, doc) => {
          if (doc.clientId) {
            return {
              ...result,
              linkedDocs: [...result.linkedDocs, doc],
            };
          }

          return {
            ...result,
            unLinkedDocs: [...result.unLinkedDocs, doc],
          };
        },
        { linkedDocs: [], unLinkedDocs: [] }
      ),
    [documentList]
  );

  useEffect(() => {
    setActiveKey(DOC_TYPE.LINKED);
  }, []);

  useEffect(() => {
    if (selectedDocId) {
      return;
    }
    const displayingList =
      activeKey === DOC_TYPE.LINKED ? linkedDocs : unLinkedDocs;
    if (displayingList?.length > 0) {
      setSelectedDocId(displayingList[0]?.id);
    } else {
      setSelectedDocId(null);
    }
  }, [activeKey, linkedDocs, setSelectedDocId, unLinkedDocs, selectedDocId]);

  const handleTabClick = useCallback((key: string) => {
    setActiveKey(key);
  }, []);

  return (
    <Tabs
      defaultActiveKey={DOC_TYPE.LINKED}
      activeKey={activeKey}
      onTabClick={handleTabClick}
    >
      <TabPane
        tab={
          <Badge count={linkedDocs.length} offset={[5, 0]}>
            <Typography.Text style={{ color: 'inherit', fontWeight: 'bold' }}>
              LINKED
            </Typography.Text>
          </Badge>
        }
        key={DOC_TYPE.LINKED}
      >
        <Documents
          docs={linkedDocs}
          selectedDocId={selectedDocId}
          setSelectedDocId={setSelectedDocId}
          refetchPractitionerMedicalResults={refetchPractitionerMedicalResults}
        />
      </TabPane>
      <TabPane
        tab={
          <Badge count={unLinkedDocs.length} offset={[5, 0]}>
            <Typography.Text style={{ color: 'inherit', fontWeight: 'bold' }}>
              UNLINKED
            </Typography.Text>
          </Badge>
        }
        key={DOC_TYPE.UNLINKED}
      >
        <Documents
          docs={unLinkedDocs}
          selectedDocId={selectedDocId}
          setSelectedDocId={setSelectedDocId}
          refetchPractitionerMedicalResults={refetchPractitionerMedicalResults}
        />
      </TabPane>
    </Tabs>
  );
};

export default DocumentList;
