import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Button, Checkbox, Divider, message, Modal, Space } from 'antd';
import styled, { css } from 'styled-components';
import IPractitionerMedicalDocument from '../../../types/IPractitionerMedicalDocument';
import { FileOutlined, InboxOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  AUS_STANDARD_DATE,
  DATE_WITH_DASH,
} from '../../../constant/time-format';
import {
  dismissPractitionerMedicalResultByDocIds,
  MarkReadPractitionerMedicalResultByDocId,
} from '../../../api/practitioner-medical-results';
import { STATUS } from '../../../constant';
import Paragraph from 'antd/lib/typography/Paragraph';

const DocItemContainer = styled.div<{ isSelected: boolean; isRead: boolean }>`
  cursor: pointer;
  padding: 5px;
  ${({ isSelected, theme, isRead }) =>
    isSelected
      ? css`
          background-color: ${theme.colors.main};
          color: ${theme.colors.white};
        `
      : css`
          &:hover {
            color: ${({ theme }) => theme.colors.hover};
          }

          background-color: transparent;
          color: ${isRead ? theme.colors.secondaryText : theme.colors.mainText};
        `};
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DocItem: FC<{
  clientFullName: IPractitionerMedicalDocument['clientName'];
  createdDate: IPractitionerMedicalDocument['createdDate'];
  clientDOB: IPractitionerMedicalDocument['clientDob'];
  id: IPractitionerMedicalDocument['id'];
  handleSelectDoc: (fileId: string) => void;
  isSelected: boolean;
  isChecked: boolean;
  handleCheck: (isCheck: boolean) => void;
  isRead: boolean;
}> = ({
  clientFullName,
  createdDate,
  handleSelectDoc,
  id,
  isSelected,
  clientDOB,
  isChecked,
  handleCheck,
  isRead,
}) => {
  const [read, setRead] = useState(false);
  useEffect(() => setRead(isRead), [isRead]);
  const handleClick = useCallback(async () => {
    handleSelectDoc(id);
    if (!isRead) {
      await MarkReadPractitionerMedicalResultByDocId(id);
      setRead(true);
    }
  }, [handleSelectDoc, id]);

  const onCheckChange = (e: CheckboxChangeEvent) => {
    handleCheck(e.target.checked);
  };

  return (
    <Space>
      <Checkbox checked={isChecked} onChange={onCheckChange} />
      <DocItemContainer
        onClick={handleClick}
        isSelected={isSelected}
        isRead={read}
      >
        <FileOutlined style={{ marginRight: 5 }} />
        {clientFullName
          ? `${clientFullName}(${moment
              .unix(createdDate)
              .format(AUS_STANDARD_DATE)})`
          : `Unknown CLINICAL CORRESPONDENCE Received on: ${moment
              .unix(createdDate)
              .format(DATE_WITH_DASH)}`}
      </DocItemContainer>
    </Space>
  );
};

interface DocItemsProps {
  docs: IPractitionerMedicalDocument[];
  selectedDocId: null | string;
  setSelectedDocId: Dispatch<SetStateAction<string>>;
  refetchPractitionerMedicalResults: () => void;
}

export const Documents: FC<DocItemsProps> = ({
  docs,
  setSelectedDocId,
  selectedDocId,
  refetchPractitionerMedicalResults,
}) => {
  //Select logic
  const handleSelectDoc = useCallback(
    (fileId: string) => {
      setSelectedDocId(fileId);
    },
    [setSelectedDocId]
  );
  const allDocIds = docs.map((doc) => doc.id);
  const [checkedDocs, setCheckedDocs] = useState([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  //Check all logic
  const [checkedAll, setCheckedAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const onCheckAllClick = (e: CheckboxChangeEvent) => {
    setCheckedDocs(e.target.checked ? allDocIds : []);
  };

  //item Check logic
  const isChecked = (id: string): boolean => checkedDocs.indexOf(id) !== -1;

  const updateCheckStatus = (id: string) => (isCheck: boolean) => {
    if (isCheck) {
      setCheckedDocs((pre) => [...pre, id]);
    } else {
      setCheckedDocs((pre) => pre.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    setCheckedAll(checkedDocs.length === allDocIds.length);
    setIndeterminate(
      !!checkedDocs.length && checkedDocs.length < allDocIds.length
    );
  }, [checkedDocs, setCheckedAll, setIndeterminate, allDocIds]);
  const [dismissResult, setDismissResult] = useState({
    status: STATUS.DEFAULT,
    error: null,
  });
  if (docs?.length < 1) {
    return <div>No document available now</div>;
  }

  const handleArchiveClick = async () => {
    if (checkedDocs && checkedDocs.length > 0) {
      setDismissResult({
        status: STATUS.LOADING,
        error: null,
      });
    }
    const { status, error } = await dismissPractitionerMedicalResultByDocIds(
      checkedDocs
    );
    if (status === STATUS.SUCCESSFUL) {
      setDismissResult({
        status: STATUS.SUCCESSFUL,
        error: null,
      });
      message.success('Dismiss selected docs successfully');
      refetchPractitionerMedicalResults();
      return;
    }

    setDismissResult({
      status: STATUS.SUCCESSFUL,
      error: error.message,
    });
    message.error('Fail to dismiss docs');
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <ActionContainer>
        <Checkbox
          checked={checkedAll}
          onChange={onCheckAllClick}
          indeterminate={indeterminate}
        >
          SELECT ALL
        </Checkbox>
        <Button
          onClick={() => setModalVisible(true)}
          loading={dismissResult.status === STATUS.LOADING}
          disabled={checkedDocs.length === 0}
          type="link"
          shape="round"
          icon={<InboxOutlined />}
          danger
        >
          ARCHIVE
        </Button>
        <Modal
          title="Do you confirm to archive these files?"
          onOk={() => handleArchiveClick()}
          confirmLoading={dismissResult.status === STATUS.LOADING}
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          okText="Confirm"
        >
          <Paragraph>Archiving will remove the files from the list.</Paragraph>
        </Modal>
      </ActionContainer>
      <Divider style={{ margin: '8px 0' }} />
      {docs.map(({ clientName, clientDob, createdDate, id, isRead }) => (
        <DocItem
          clientFullName={clientName}
          createdDate={createdDate}
          clientDOB={clientDob}
          id={id}
          key={id}
          handleSelectDoc={handleSelectDoc}
          isSelected={id === selectedDocId}
          isChecked={isChecked(id)}
          handleCheck={updateCheckStatus(id)}
          isRead={Boolean(isRead)}
        />
      ))}
    </Space>
  );
};
