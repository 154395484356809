import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from 'firebase/analytics';
import firebase from './firebase';
import { SITE } from '../constant';

class Analytics {
  analytics;

  constructor() {
    this.analytics = getAnalytics(firebase.app);
  }

  logEvent(name, parameters) {
    logEvent(this.analytics, name, {
      site: SITE,
      ...parameters,
    });
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      console.info('Event Tracking: ', name, {
        site: SITE,
        ...parameters,
      });
    }
  }

  setUserId(userId) {
    setUserId(this.analytics, userId);
  }

  setUserProperties(properties) {
    setUserProperties(this.analytics, properties);
  }
}

const analytics = new Analytics();
export default analytics;
