import React, { ErrorInfo, ReactNode } from 'react';
import Error from './Error';

interface IProps {
  children: ReactNode;
}

interface IState {
  hasError: boolean;
  message: string;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error({
      event: 'renderFailed',
      ...error,
      ...info,
    });
  }

  render() {
    if (this.state.hasError) {
      return <Error message={this.state.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
