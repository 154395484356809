export const splitNotes = (notes) => {
  if (!notes) {
    return;
  }
  const suitabilityMapping = {
    'Suitability:\nYES': 'suitability:Y',
    'Suitability:\nNO': 'suitability:N',
  };
  const noteList = notes.split('\n\n');

  if (noteList.length === 3) {
    const [suitabilityStr, availabilityStr, notesStr] = noteList;
    return {
      suitability: suitabilityMapping[suitabilityStr],
      availability: availabilityStr.split('\n')[1],
      notes: notesStr.split('\n')[1],
    };
  }
};
