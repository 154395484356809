import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Alert, Col, Form, Input, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { STATUS } from '../../../constant';
import auth from '../../../services/auth';
import PrimaryButton from '../../../components/PrimaryButton';

const ResetPasswordContainer = styled.div`
  padding-top: 60px;
  text-align: center;
  position: relative;
  background-color: white;
`;

const { Text, Title } = Typography;

const PasswordReset = () => {
  const [emailResetResult, setEmailResetResult] = useState({
    error: '',
    status: STATUS.DEFAULT,
  });

  const onFinish = async (values) => {
    const { email } = values;
    setEmailResetResult({ error: '', status: STATUS.LOADING });

    try {
      await auth.sendPasswordResetEmail(email);
      setEmailResetResult({
        status: STATUS.SUCCESSFUL,
        error: '',
      });
    } catch (error) {
      setEmailResetResult({
        status: STATUS.FAILED,
        error: error.message,
      });
    }
  };

  return (
    <Row>
      <Col offset={9} span={6}>
        <ResetPasswordContainer>
          <Col offset={6} span={12} style={{ marginBottom: '30px' }}>
            <img alt="logo" width={'100%'} src="/telecare-logo.png" />
          </Col>
          <Title level={4} style={{ margin: '60px 0 20px' }}>
            Forgot Your Password?
          </Title>
          <Content>
            <Text
              style={{
                maxWidth: '360px',
                display: 'inline-block',
                marginBottom: '30px',
              }}
            >
              Enter your primary email address and we’ll send you instructions
              on how to reset your password.
            </Text>
            <Form name="reset-password" onFinish={onFinish}>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    validateTrigger: 'onSubmit',
                    message: 'The email address is invalid.',
                  },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>
              {emailResetResult.status === STATUS.FAILED && (
                <Alert description={emailResetResult.error} type="error" />
              )}
              {emailResetResult.status === STATUS.SUCCESSFUL && (
                <Alert
                  description={
                    <>
                      We have sent you an email with a link to reset your
                      password, please have a look! Then you can click{' '}
                      <Link to="/">
                        <strong>Back To Sign In Page</strong>
                      </Link>{' '}
                      to sign in again.
                    </>
                  }
                  type="success"
                  showIcon
                />
              )}
              <Form.Item>
                <PrimaryButton
                  className="reset-password-btn"
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: '20px' }}
                  loading={emailResetResult.status === STATUS.LOADING}
                  shape="round"
                  block
                  size="large"
                >
                  Reset My Password
                </PrimaryButton>
              </Form.Item>
              <Link to="/">Go back to sign in.</Link>
            </Form>
          </Content>
        </ResetPasswordContainer>
      </Col>
    </Row>
  );
};

export default PasswordReset;
