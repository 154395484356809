import { Route, Switch } from 'react-router-dom';
import React from 'react';
import SpecialistConfirmation from '../pages/SpecialistConfirmation';
import NotFound from '../pages/NotFound';
import { RESET_PASSWORD, WORKFLOW } from '../constant/routes';
import RoutesWithAuth from './RoutesWithAuth';
import ResetPassword from '../pages/Login/ResetPassword';

const RootRoutes = () => {
  return (
    <Switch>
      <Route path={WORKFLOW}>
        <SpecialistConfirmation />
      </Route>

      <Route path={RESET_PASSWORD} exact>
        <ResetPassword />
      </Route>

      <Route path="/">
        <RoutesWithAuth />
      </Route>

      <Route component={NotFound} />
    </Switch>
  );
};
export default RootRoutes;
