import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';

const isNonProd = process.env.NODE_ENV !== 'production';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = [...getDefaultMiddleware(), thunkMiddleware];
    return isNonProd ? [...middlewares, logger] : middlewares;
  },
  devTools: isNonProd,
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
}

export default store;
