import { createSlice } from '@reduxjs/toolkit';
import { STATUS } from '../constant';

const { SUCCESSFUL, DEFAULT } = STATUS;

const emptyUser = {
  coreplusId: '',
  role: '',
  displayName: '',
};

const initialState = {
  ...emptyUser,
  loginStatus: DEFAULT,
  loginError: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoginStatus(state, action) {
      state.loginStatus = action.payload;
    },
    setLoginError(state, action) {
      state.loginError = action.payload;
    },
    setUser(state, action) {
      const { firstName, lastName, role, coreplusId, timezone } =
        action.payload;
      state.displayName = `${firstName} ${lastName}`;
      state.role = role;
      state.coreplusId = coreplusId;
      state.loginStatus = SUCCESSFUL;
      state.timezone = timezone;
    },
    resetUser(state) {
      return { ...state, ...emptyUser };
    },
    logoutUser() {
      return { ...initialState };
    },
  },
});

export const { setUser, setLoginStatus, resetUser, logoutUser, setLoginError } =
  userSlice.actions;
export default userSlice.reducer;
