import { useSelector } from 'react-redux';
import React, { FC, useCallback, useState } from 'react';
import {
  Alert,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Row,
  Typography,
} from 'antd';
import IPractitionerMedicalDocument from '../../../types/IPractitionerMedicalDocument';
import { transformTodo, validateTodo } from '../tools';
import { createTodo } from '../../../api/todo';
import { STATUS } from '../../../constant';
import IRootReducer from '../../../types/IRootReducer';
import IUserReducer from '../../../types/IUserReducer';
import './TodoModal.css';

interface ITodoModalProps {
  isModalVisible: boolean;
  onCancel: (form: FormInstance) => void;
  selectedDoc: IPractitionerMedicalDocument;
}

const TodoModal: FC<ITodoModalProps> = ({
  isModalVisible,
  onCancel,
  selectedDoc,
}) => {
  const { coreplusId } = useSelector<IRootReducer, IUserReducer>(
    (state) => state.user
  );
  const [createStatus, setCreateStatus] = useState(STATUS.DEFAULT);
  const [form] = Form.useForm();

  const addTodo = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (e) {
      console.error({
        event: 'todoCreatFailed',
        message: e.errorFields,
      });
      return;
    }

    try {
      const todo = {
        ...values,
        clientName: selectedDoc.clientName,
        createdBy: coreplusId,
      };
      const isValid = validateTodo(todo);

      if (!isValid) {
        console.error({
          event: 'todoIsInvalid',
        });
        return;
      }

      const formattedTodo = transformTodo(todo);
      const { status } = await createTodo(formattedTodo);

      if (status === STATUS.SUCCESSFUL) {
        setCreateStatus(STATUS.SUCCESSFUL);
        form.resetFields();
        return;
      }

      return setCreateStatus(STATUS.FAILED);
    } catch (e) {
      console.error({
        event: 'todoCreatFailed',
        message: e,
      });
      setCreateStatus(STATUS.FAILED);
    }
  }, [coreplusId, selectedDoc?.clientName, form]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Modal
      title="ASSIGN TASK"
      width={600}
      visible={isModalVisible}
      onOk={addTodo}
      onCancel={() => onCancel(form)}
      destroyOnClose={true}
    >
      <Typography.Text
        style={{ color: 'gray', display: 'block', marginBottom: '30px' }}
      >
        This task will be assigned to Telecare agent directly.
      </Typography.Text>
      <Form id="add-todo-form" name="todo" form={form} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item label="PATIENT NAME">
              <span className="ant-form-text">{selectedDoc.clientName}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="DUE DATE"
              name="dueDate"
              rules={[{ required: true, message: 'Please input this field!' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="NOTE(Urgent result doctor review:)"
          name="note"
          rules={[{ required: true, message: 'Please input this field!' }]}
        >
          <Input.TextArea allowClear />
        </Form.Item>

        <Form.Item name="isUrgent" valuePropName="checked">
          <Checkbox>Mark As Urgent</Checkbox>
        </Form.Item>

        {createStatus === STATUS.FAILED && (
          <Alert type="error" message="Sorry, todo creation failed" />
        )}
        {createStatus === STATUS.SUCCESSFUL && (
          <Alert
            type="success"
            message="Congratulations, Todo created successfully"
          />
        )}
      </Form>
    </Modal>
  );
};

export default TodoModal;
