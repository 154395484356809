import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWorkflow } from '../api/workflow';
import { STATUS } from '../constant';

export const fetchWorkflow = createAsyncThunk(
  'workflow/fetchByWorkflowId',
  async (workflowId) => {
    const { data } = await getWorkflow(workflowId);
    return data;
  }
);

const workflowSlice = createSlice({
  name: 'workflow',
  initialState: { data: {}, status: STATUS.DEFAULT },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorkflow.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.status = STATUS.SUCCESSFUL;
      })
      .addCase(fetchWorkflow.rejected, (state) => {
        state.status = STATUS.FAILED;
      })
      .addCase(fetchWorkflow.pending, (state) => {
        state.status = STATUS.LOADING;
      });
  },
});

export default workflowSlice.reducer;
