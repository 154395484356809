import { useEffect, useState } from 'react';
import request from '../services/request';
import { STATUS } from '../constant';

const { DEFAULT, SUCCESSFUL, FAILED, LOADING } = STATUS;

interface IRespond<T> {
  data?: T;
  status: STATUS;
  error?: any;
  refetch: () => void;
}

const useQuery = <T>(url: string, isReadyToQuery: boolean): IRespond<T> => {
  const [status, setStatus] = useState<STATUS>(DEFAULT);
  const [data, setData] = useState<T>(null);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    if (!isReadyToQuery) {
      return;
    }
    try {
      setStatus(LOADING);
      const { data } = await request.get(url);
      setStatus(SUCCESSFUL);
      setData(data);
    } catch (error) {
      setStatus(FAILED);
      setError(error);
    }
  };

  const refetch = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [url, isReadyToQuery]);

  return {
    status,
    data,
    error,
    refetch,
  };
};

export default useQuery;
