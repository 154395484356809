import request from '../../services/request';
import { PRACTITIONER_MEDICAL_RESULTS } from '../../constant/apis';
import { StatusCodes } from 'http-status-codes';
import { STATUS } from '../../constant';

export const dismissPractitionerMedicalResultByDocId = async (
  docId: string
) => {
  try {
    const { status } = await request.delete(
      `${PRACTITIONER_MEDICAL_RESULTS}/${docId}`
    );
    if (status === StatusCodes.NO_CONTENT) {
      return {
        status: STATUS.SUCCESSFUL,
      };
    }

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docId}`,
      },
    };
  } catch (e) {
    console.error({
      event: 'failToDismissMedicalResult',
      message: e.message,
    });

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docId}, error is: ${e.message}`,
      },
    };
  }
};

export const dismissPractitionerMedicalResultBySpecialistId = async (
  specialistId: string
) => {
  try {
    const { status } = await request.delete(
      `${PRACTITIONER_MEDICAL_RESULTS}/specialist/${specialistId}`
    );
    if (status === StatusCodes.NO_CONTENT) {
      return {
        status: STATUS.SUCCESSFUL,
      };
    }

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss all specialist medical result by specialistId: ${specialistId}`,
      },
    };
  } catch (e) {
    console.error({
      event: 'failToDismissMedicalResult',
      message: e.message,
    });

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss all specialist medical result by specialistId: ${specialistId}, error is: ${e.message}`,
      },
    };
  }
};

export const dismissPractitionerMedicalResultByDocIds = async (
  docIds: Array<string>
) => {
  if (docIds.length === 0) {
    return;
  }
  try {
    const { status } = await request.patch(
      `${PRACTITIONER_MEDICAL_RESULTS}/batch-delete`,
      {
        ids: docIds,
      }
    );
    if (status === StatusCodes.NO_CONTENT) {
      return {
        status: STATUS.SUCCESSFUL,
      };
    }

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docIds}`,
      },
    };
  } catch (e) {
    console.error({
      event: 'failToDismissMedicalResult',
      message: e.message,
    });

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docIds}, error is: ${e.message}`,
      },
    };
  }
};

export const MarkReadPractitionerMedicalResultByDocId = async (
  docId: string
) => {
  try {
    const { status } = await request.patch(
      `${PRACTITIONER_MEDICAL_RESULTS}/${docId}/read-status`,
      {
        isRead: true,
      }
    );
    if (status === StatusCodes.NO_CONTENT) {
      return {
        status: STATUS.SUCCESSFUL,
      };
    }

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docId}`,
      },
    };
  } catch (e) {
    console.error({
      event: 'failToDismissMedicalResult',
      message: e.message,
    });

    return {
      status: STATUS.FAILED,
      error: {
        code: 'failed_to_dismiss',
        message: `Failed to dismiss practitioner medical result: ${docId}, error is: ${e.message}`,
      },
    };
  }
};
