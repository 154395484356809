import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';

export const NoDocument = () => (
  <>
    <Typography.Title level={5}>
      <InfoCircleOutlined />
      DETAILS
    </Typography.Title>
    No Document is selected
  </>
);
