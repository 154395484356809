import firebase from './firebase';
import {
  getAuth,
  setPersistence,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  onIdTokenChanged,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';

class Auth {
  auth;
  currentUser;

  constructor() {
    this.auth = getAuth(firebase.app);
    this.currentUser = this.auth.currentUser;
  }

  signInWithEmailAndPassword = async (email, password, authPersistenceType) => {
    if (authPersistenceType) {
      await setPersistence(this.auth, authPersistenceType);
    }
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  onAuthStateChanged = (callback) => {
    return onAuthStateChanged(this.auth, callback);
  };

  onIdTokenChanged = (callback) => {
    return onIdTokenChanged(this.auth, callback);
  };

  getUid = () => {
    const auth = getAuth(this.app);
    const user = auth.currentUser;

    return user ? user.uid : '';
  };

  sendPasswordResetEmail = async (email) => {
    console.log(this.auth);
    await sendPasswordResetEmail(this.auth, email);
  };

  signOut = () => signOut(this.auth);
}

export default new Auth();
