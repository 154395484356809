import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    error: null,
  },
  reducers: {
    setAppError(state, action) {
      state.error = action.payload.debug || 'unknown error';
    },
  },
});

export const { setAppError } = appSlice.actions;

export default appSlice.reducer;
