import IPractitionerMedicalDocument from '../../types/IPractitionerMedicalDocument';
import { ALLOWED_EXTENSIONS, FILE_TYPES, URGENCY } from '../../constant';
import { ITodo } from '../../types/ITodo';

export const findDocsByDocId = (
  documentList: IPractitionerMedicalDocument[],
  docId: string
): IPractitionerMedicalDocument | undefined => {
  if (!docId) {
    return;
  }
  return documentList.find(({ id }) => id === docId);
};

export const getFileTypeByFileExtension = (fileName: string) => {
  if (!fileName) {
    return '';
  }

  const extension = getFileExtension(fileName);

  if (ALLOWED_EXTENSIONS[FILE_TYPES.DOC].includes(extension)) {
    return FILE_TYPES.DOC;
  }

  if (ALLOWED_EXTENSIONS[FILE_TYPES.IMAGE].includes(extension)) {
    return FILE_TYPES.IMAGE;
  }

  return '';
};

export const getFileExtension = (url: string) =>
  url.split(/[#?]/)[0].split('.').pop().trim();

interface ITodoData {
  dueDate: string;
  note: string;
  isUrgent: boolean;
  clientName: string;
  createdBy: string;
}

export const validateTodo = (todo: ITodoData): boolean => {
  const { dueDate, note, clientName, createdBy } = todo;
  return Boolean(dueDate && note && clientName && createdBy);
};

export const transformTodo = (todo: ITodoData): ITodo => {
  const { dueDate, note, isUrgent, clientName, createdBy } = todo;

  return {
    description: `Urgent result doctor review: ${note}`,
    dueDate,
    clientName,
    urgency: isUrgent ? URGENCY.HIGH : URGENCY.MEDIUM,
    createdBy: createdBy,
  };
};
