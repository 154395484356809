import React from 'react';
import { Layout, Typography, Row } from 'antd';
import LoginForm from './LoginForm';
import TelecareFooter from '../../components/Footer';
import { LoginContainer, WelcomeToTelecareContainer, Spacer } from './styles';
import theme from '../../styles/theme';

const { Title, Text } = Typography;
const { Content } = Layout;

const Login = () => {
  return (
    <LoginContainer>
      <Row justify="center">
        <img alt="logo" width="50%" src="/slogan.svg" />
      </Row>

      <Content style={{ marginTop: 60 }}>
        <LoginForm />
        <WelcomeToTelecareContainer>
          <Title level={3}>Hi, Welcome to Telecare!</Title>
          <Spacer height={44} />
          <Text
            style={{
              color: theme.colors.secondaryText,
              maxWidth: '360px',
              display: 'inline-block',
            }}
          >
            Contact Telecare for Login Details at (03 9052 4872) or via live
            chat.
          </Text>
          <Spacer height={56} />
          <img src="/doctors-1.svg" alt="Doctors" />
        </WelcomeToTelecareContainer>
      </Content>
      <TelecareFooter />
    </LoginContainer>
  );
};

export default Login;
