import styled from 'styled-components';
import { Card, Row } from 'antd';

export const StyledContent = styled(Row)`
  background-color: white;
  padding: 15px 20px;
`;

export const StyledCard = styled(Card)`
  width: 100%;
  border-radius: 10px;
  height: calc(100vh - 150px);
  overflow: hidden;
  overflow-y: scroll;
  border: none;
  & .ant-card-body {
    padding: 15px;
  }
`;

export const DocumentListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
