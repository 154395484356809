import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { AUTH_APIS } from '../constant/apis';

const request = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTIONS,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

request.interceptors.request.use(async (request) => {
  const url = request.url;
  const needAuth = AUTH_APIS.some((path) => url.includes(path));

  if (needAuth) {
    const token = localStorage.getItem('token');

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
      return request;
    } else {
      console.error({
        status: StatusCodes.UNAUTHORIZED,
        code: 'token_not_exists',
        message: 'Can not find auth token to send request',
      });
      throw {
        status: StatusCodes.UNAUTHORIZED,
        code: 'token_not_exists',
        message: 'Can not find auth token to send request',
      };
    }
  } else {
    return request;
  }
});

export default request;
