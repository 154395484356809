/* eslint-disable no-unused-vars */
export enum STATUS {
  LOADING = 'loading',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
  DEFAULT = 'default',
}

export const EXTERNAL_LINKS = {
  REFERRAL_SUBMISSION:
    'https://telecare.snapforms.com.au/form/gp-patient-documents',
  FEEDBACK: 'https://telecare.snapforms.com.au/form/telecareconnect-feedback',
  TERMS_OF_USE: 'https://www.telecare.com.au/terms-of-use',
  PRIVACY_POLICY: 'https://www.telecare.com.au/privacy-policy',
  SUPPORTING_DOCUMENTS:
    'https://telecare.snapforms.com.au/form/gp-portal---submit-supporting-documents-',
  PATIENT_DOC: 'https://telecare.snapforms.com.au/form/gp-patient-documents',
};

export const SITE = 'specialist-portal';

export const SPECIALIST_USER_ROLE = 'SPECIALIST';

export const FILE_TYPES = {
  DOC: 'doc',
  IMAGE: 'image',
};

export const ALLOWED_EXTENSIONS = {
  [FILE_TYPES.DOC]: ['doc', 'docx', 'rtf', 'pdf'],
  [FILE_TYPES.IMAGE]: ['png', 'jpeg', 'jpg', 'svg'],
};

export enum URGENCY {
  HIGH = 1,
  MEDIUM,
  LOW,
}

export enum DOC_TYPE {
  LINKED = 'linked',
  UNLINKED = 'unlinked',
}
