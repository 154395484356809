import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

const Container = styled.div`
  width: max-content;
  margin: 100px auto 0;
  text-align: center;
`;

const NotFound = () => {
  return (
    <Container>
      <img alt="logo" src="/slogan.svg" />
      <Title level={5} style={{ margin: '50px 0' }}>
        Unauthorised
      </Title>
      <img alt="doctors" src="/doctors-1.svg" />
    </Container>
  );
};
export default NotFound;
