import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Typography, Alert, Input, Spin } from 'antd';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router-dom';

import { login } from '../../../api/workflow';
import { STATUS } from '../../../constant';
import { useSelector } from 'react-redux';

const { SUCCESSFUL, FAILED, LOADING, DEFAULT } = STATUS;

const Container = styled.div`
  width: 90%;
  margin: 60px auto;
  text-align: center;
  max-width: 350px;
`;

const AuthenticationForm = (props) => {
  const { updateAuthenticationResult } = props;
  const { workflowId } = useParams();
  const passwordInURL = new URLSearchParams(useLocation().search).get(
    'password'
  );
  const [status, setStatus] = useState(DEFAULT);
  const workflow = useSelector(({ workflow }) => workflow);
  const doctorReplied = workflow?.data?.doctorReplied;

  const handleVerifyPassword = useCallback(
    async (password) => {
      setStatus(LOADING);
      const { status } = await login({ workflowId, password });
      if (status === STATUS.SUCCESSFUL) {
        setStatus(SUCCESSFUL);
        updateAuthenticationResult(true);
      } else {
        setStatus(FAILED);
        updateAuthenticationResult(false);
      }
    },
    [updateAuthenticationResult, workflowId]
  );

  useEffect(() => {
    if (passwordInURL !== null) {
      handleVerifyPassword(passwordInURL);
    }
  }, [passwordInURL, handleVerifyPassword]);

  const handleSubmitPassword = useCallback(
    async (value) => {
      const { password } = value;
      await handleVerifyPassword(password);
    },
    [handleVerifyPassword]
  );

  if (passwordInURL && status !== FAILED) {
    return <Spin size="large" style={{ width: '100%', marginTop: 60 }} />;
  }

  return (
    <Container>
      <img src="/slogan.svg" alt="Logo" />
      <Form onFinish={handleSubmitPassword} style={{ margin: '30px 0' }}>
        <Typography.Title level={3}>Verify Password</Typography.Title>
        <Typography.Text>
          please enter the one-time password you received via email to view this
          referral
        </Typography.Text>
        <Form.Item
          label="Password"
          name="password"
          required
          rules={[{ required: true, message: 'Please input your password!' }]}
          style={{
            maxWidth: 300,
            margin: '40px 0',
          }}
        >
          <Input.Password placeholder="input password" />
        </Form.Item>

        <Button
          htmlType="submit"
          style={{ borderRadius: '22px' }}
          disabled={status === LOADING}
        >
          Enter
        </Button>

        {status === FAILED && (
          <Alert
            type="error"
            style={{ marginTop: '40px' }}
            message={
              doctorReplied
                ? 'You have already responded to this referral. If you would like to make changes, please contact us via email (admin@telecareonline.com.au) or via phone (03 9052 4872).'
                : 'Incorrect password entered. Please try again. If you are having issues, please contact us via email (admin@telecareonline.com.au) or via phone (03 9052 4872).'
            }
          />
        )}
      </Form>
    </Container>
  );
};

export default AuthenticationForm;
