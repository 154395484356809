import React from 'react';
import styled from 'styled-components';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import auth from '../../services/auth';
import { logoutUser } from '../../slices/userSlice';

const { Header } = Layout;

const StyledHeader = styled(Header)`
  background-color: ${({ theme: { colors } }) => colors.main};
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.whiteText};
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
  font-size: 1rem;
`;

const AppHeaderBar = () => {
  const dispatch = useDispatch();
  const { displayName } = useSelector((state) => state.user);

  const handleLogout = async () => {
    dispatch(logoutUser());
    return auth.signOut();
  };

  const ProfileMenu = (
    <Menu
      onClick={(e) => {
        if (e.key === 'logout') {
          handleLogout();
        }
      }}
    >
      <Menu.Item icon={<LogoutOutlined />} key="logout">
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <StyledHeader>
      <img src="/telecare-logo-white.svg" alt="logo" height="44px" />
      {displayName && (
        <Dropdown overlay={ProfileMenu}>
          <DropdownContainer>
            <Avatar
              size={44}
              icon={<UserOutlined />}
              style={{ marginRight: 10 }}
            />
            <div>
              <span className="user-name">{displayName}</span>
            </div>
          </DropdownContainer>
        </Dropdown>
      )}
    </StyledHeader>
  );
};

export default AppHeaderBar;
