import { Layout } from 'antd';
import styled from 'styled-components';

export const LoginContainer = styled(Layout)`
  background-repeat: no-repeat;
  background-position: center 110px;
  background-size: 100%;
  padding-top: 60px;
  background-color: ${({ theme: { colors } }) => colors.whiteText};
  width: 30%;
  margin: auto;

  & .ant-layout-footer {
    font-size: 12px;
    padding: 24px 0;
    background-color: ${({ theme: { colors } }) => colors.whiteText};
  }

  &.ant-layout {
    background-color: ${({ theme: { colors } }) => colors.whiteText};
  }
`;

export const Spacer = styled.div`
  height: ${(props) => props.height}px;
`;

export const WelcomeToTelecareContainer = styled.div`
  text-align: center;
  margin-top: 60px;
`;
