import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Radio, Typography, Alert, Input } from 'antd';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { updateWorkflow } from '../../../api/workflow';
import { fetchWorkflow } from '../../../slices/worflowSlice';
import { splitNotes } from '../../../tools/workflow';
import { STATUS } from '../../../constant';

const Container = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  padding: 64px 32px;
  flex-direction: column;
  align-items: center;

  & .form {
    width: 65%;
    margin-top: 24px;
  }

  & .submit {
    height: 44px;
    margin: 24px 0 16px;
    border-radius: 22px;
  }

  & .item {
    margin-top: 16px;
  }
`;

const TitleContainer = styled(Typography.Title)`
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.blueText};
  margin: 35px 0 !important;
`;

const { SUCCESSFUL, FAILED, LOADING, DEFAULT } = STATUS;

export default function DoctorFeedBack() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { workflowId } = useParams();
  const [updateStatus, setUpdateStatus] = useState(DEFAULT);

  const workflow = useSelector(({ workflow }) => workflow);
  const doctorReplied = workflow?.data?.doctorReplied;
  const workflowNotes = workflow?.data?.notes;

  useEffect(() => {
    const noteObj = splitNotes(workflowNotes);
    if (noteObj) {
      const { suitability, availability, notes } = noteObj;
      form.setFields([
        {
          name: 'suitability',
          value: suitability,
        },
        {
          name: 'availability',
          value: availability,
        },
        {
          name: 'notes',
          value: notes,
        },
      ]);
    }
  }, [workflowNotes, form]);

  const handleSubmitReply = useCallback(
    async (values) => {
      setUpdateStatus(LOADING);
      const { suitability, availability, notes } = values;
      const message =
        `${suitability === 'suitability:Y' ? 'YES' : 'NO'}` +
        '\n' +
        'Availability:\n' +
        `${availability}` +
        '\n' +
        'Notes:\n' +
        `${notes}`;
      const newWorkflowFragment = {
        notes: message,
      };

      const { status } = await updateWorkflow(
        workflowId,
        newWorkflowFragment,
        suitability === 'suitability:Y'
      );
      if (status === STATUS.SUCCESSFUL) {
        setUpdateStatus(SUCCESSFUL);
        dispatch(fetchWorkflow(workflowId));
      } else {
        setUpdateStatus(FAILED);
      }
    },
    [dispatch, workflowId]
  );

  return (
    <Container>
      <img src="/slogan.svg" alt="Logo" />
      <TitleContainer level={3}>Appointment Confirmation</TitleContainer>
      <Form
        name="dockerFeedback"
        onFinish={handleSubmitReply}
        layout="vertical"
        form={form}
      >
        <Form.Item label="Accept referral?" name="suitability" required>
          <Radio.Group aria-label="suitability">
            <Radio value="suitability:Y">Yes</Radio>
            <Radio value="suitability:N">No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="availability"
          label="Nominate specific appointment time (optional)"
          tooltip="If you leave this blank, Telecare will book an appointment time that is free in your calendar. You can nominate a specific appointment time, however this is subject to patient availability."
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="notes"
          label="Add booking notes (optional)"
          tooltip="Add any instructions for the Telecare booking team."
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            block
            shape="round"
            type="primary"
            htmlType="submit"
            disabled={
              doctorReplied || [LOADING, SUCCESSFUL].includes(updateStatus)
            }
          >
            Submit
          </Button>
        </Form.Item>

        {updateStatus === FAILED && (
          <Alert type="error" message="Fail to submit!" />
        )}

        {updateStatus === SUCCESSFUL && (
          <Alert type="success" message="Reply successfully!" />
        )}
      </Form>
    </Container>
  );
}
