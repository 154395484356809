import {
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

import analytics from '../services/analytics';
import auth from '../services/auth';
import { STATUS, SPECIALIST_USER_ROLE } from '../constant';

export const trackUserLoginEvent = (data) => {
  const { uid, firstName, lastName, role, coreplusId } = data;
  analytics.setUserId(uid);
  analytics.setUserProperties({
    name: `${firstName} ${lastName}`,
    role,
    coreplusId,
  });
  if (role === SPECIALIST_USER_ROLE) {
    analytics.logEvent('login', {
      clinic_name: `${firstName} ${lastName}`,
      user_role: role,
      user_coreplus_id: coreplusId,
    });
  }
};

export const loginFirebase = async (params) => {
  try {
    const { userName, password, remember } = params;
    const authPersistenceType = !remember
      ? browserSessionPersistence
      : browserLocalPersistence;
    await auth.signInWithEmailAndPassword(
      userName,
      password,
      authPersistenceType
    );
    return { status: STATUS.SUCCESSFUL };
  } catch (error) {
    return { status: STATUS.FAILED };
  }
};
