const theme = {
  colors: {
    main: '#4CACE9',
    white: '#fff',
    blueText: '#000f48',
    whiteText: '#fff',
    secondaryText: '#00000073',
    mainText: '#000000D9',
    transparent: '#00000000',
    background: '#F0F2F5',
    border: '#F0F0F0',
    hover: '#1890ff',
  },
};

export default theme;
