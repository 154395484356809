import React, { FC, useCallback, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import PrimaryButton from '../PrimaryButton';
import { getFileExtension } from '../../pages/Home/tools';
import { STATUS } from '../../constant';
import { DownloadOutlined } from '@ant-design/icons';

const DownloadButton: FC<{ fileURL: string; fileName: string }> = ({
  fileURL,
  fileName,
}) => {
  const [downloadStatus, setDownloadStatus] = useState(STATUS.DEFAULT);

  const handleDownloadFile = useCallback(async () => {
    setDownloadStatus(STATUS.LOADING);
    try {
      const res = await axios.get(fileURL, { responseType: 'blob' });
      if (res.data) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const downloadATag = document.createElement('a');
        downloadATag.href = url;
        downloadATag.setAttribute(
          'download',
          `${fileName || 'file'}.${getFileExtension(fileURL)}`
        );
        document.body.appendChild(downloadATag);
        downloadATag.click();
        downloadATag.parentNode.removeChild(downloadATag);
        window.URL.revokeObjectURL(url);
        setDownloadStatus(STATUS.SUCCESSFUL);
      }
      setDownloadStatus(STATUS.FAILED);
    } catch (error) {
      console.error({
        event: 'downloadFileFailed',
        message: error.message,
        fileURL,
      });
      message.error('Download failed');
      setDownloadStatus(STATUS.FAILED);
    }
  }, [fileName, fileURL]);

  return (
    <>
      <PrimaryButton
        type="primary"
        block
        loading={downloadStatus === STATUS.LOADING}
        onClick={handleDownloadFile}
        disabled={!fileURL}
        shape="round"
        icon={<DownloadOutlined />}
      >
        DOWNLOAD
      </PrimaryButton>
    </>
  );
};

export default DownloadButton;
