import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import AppHeaderBar from '../pages/AppHeaderBar';
import RootRoutes from '../routes/RootRoutes';

const { Content } = Layout;

const StyledContent = styled(Content)`
  margin-top: 64px;
  background-color: ${({ theme: { colors } }) => colors.white};
  height: calc(100vh - 64px);
`;

const App = () => {
  return (
    <Layout>
      <AppHeaderBar />
      <StyledContent>
        <RootRoutes />
      </StyledContent>
    </Layout>
  );
};

export default App;
