import React, { FC, useCallback, useState } from 'react';
import { Button, message, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dismissPractitionerMedicalResultByDocId } from '../../../api/practitioner-medical-results';
import { STATUS } from '../../../constant';

const DISMISS_TOOLTIP =
  'Archive this file after review, otherwise the list gets built up, and you will be notified if the list has any remaining files';
const DismissAction: FC<{
  selectedDocId: string;
  refetchPractitionerMedicalResults: () => void;
}> = ({ selectedDocId, refetchPractitionerMedicalResults }) => {
  const [dismissResult, setDismissResult] = useState({
    status: STATUS.DEFAULT,
    error: null,
  });

  const handleDismissDoc = useCallback(async () => {
    if (selectedDocId) {
      setDismissResult({
        status: STATUS.LOADING,
        error: null,
      });
      const { status, error } = await dismissPractitionerMedicalResultByDocId(
        selectedDocId
      );

      if (status === STATUS.SUCCESSFUL) {
        setDismissResult({
          status: STATUS.SUCCESSFUL,
          error: null,
        });
        message.success('Dismiss the doc successfully');
        refetchPractitionerMedicalResults();
        return;
      }

      setDismissResult({
        status: STATUS.SUCCESSFUL,
        error: error.message,
      });
      message.error('Fail to dismiss selected doc');
    }
  }, [selectedDocId, refetchPractitionerMedicalResults]);

  return (
    <Tooltip title={DISMISS_TOOLTIP} placement="bottom">
      <Button
        type="default"
        shape="round"
        disabled={!selectedDocId}
        loading={dismissResult.status === STATUS.LOADING}
        icon={<InboxOutlined />}
        danger
        onClick={handleDismissDoc}
      >
        ARCHIVE WITHOUT ACTION
      </Button>
    </Tooltip>
  );
};

export default DismissAction;
