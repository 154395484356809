import React, { FC, useCallback, useState } from 'react';
import { Button, message, Modal, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dismissPractitionerMedicalResultBySpecialistId } from '../../../api/practitioner-medical-results';
import { STATUS } from '../../../constant';
import Paragraph from 'antd/lib/typography/Paragraph';

const ARCHIVE_ALL_TOOLTIP =
  'Allows you to archive all the files both in LINKED and UNLINKED tabs.';
const DismissAllAction: FC<{
  specialistId: string;
  refetchPractitionerMedicalResults: () => void;
}> = ({ specialistId, refetchPractitionerMedicalResults }) => {
  const [dismissResult, setDismissResult] = useState({
    status: STATUS.DEFAULT,
    error: null,
  });
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const handleDismissAllDocsBySpecialistId = useCallback(async () => {
    if (specialistId) {
      setDismissResult({
        status: STATUS.LOADING,
        error: null,
      });
      const { status, error } =
        await dismissPractitionerMedicalResultBySpecialistId(specialistId);

      if (status === STATUS.SUCCESSFUL) {
        setDismissResult({
          status: STATUS.SUCCESSFUL,
          error: null,
        });
        message.success('Dismiss all docs successfully');
        refetchPractitionerMedicalResults();
        return;
      }

      setDismissResult({
        status: STATUS.SUCCESSFUL,
        error: error.message,
      });
      message.error('Fail to dismiss docs');
    }
  }, [specialistId, refetchPractitionerMedicalResults]);

  // eslint-disable-next-line
  return (
    <>
      <Tooltip title={ARCHIVE_ALL_TOOLTIP}>
        <Button
          type="link"
          shape="round"
          style={{
            width: '80%',
            display: 'block',
            margin: '10px auto 0',
          }}
          disabled={!specialistId}
          loading={dismissResult.status === STATUS.LOADING}
          icon={<InboxOutlined />}
          danger
          onClick={() => setModalVisible(true)}
        >
          ARCHIVE ALL
        </Button>
      </Tooltip>
      <Modal
        title="Do you confirm to archive these files?"
        onOk={handleDismissAllDocsBySpecialistId}
        confirmLoading={dismissResult.status === STATUS.LOADING}
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        okText="Confirm"
      >
        <Paragraph>
          Archiving all will remove all the files both in LINKED and UNLINKED
          tabs.
        </Paragraph>
      </Modal>
    </>
  );
};

export default DismissAllAction;
