import React, { FC, useCallback, useState } from 'react';
import PrimaryButton from '../../../components/PrimaryButton';
import TodoModal from './TodoModal';
import IPractitionerMedicalDocument from '../../../types/IPractitionerMedicalDocument';
import { FormInstance, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

interface IAddTodoActionProps {
  selectedDoc: IPractitionerMedicalDocument;
}

const ASSIGN_TASK_TOOLTIP =
  'Assign task button allows you to assign further action you would like to instruct the admin and nursing team to handle for this patient if needed.';
const AddTodoAction: FC<IAddTodoActionProps> = ({ selectedDoc }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closeModal = useCallback((form: FormInstance) => {
    form.resetFields();
    setIsModalVisible(false);
  }, []);

  const handleClick = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  return (
    <>
      <Tooltip title={ASSIGN_TASK_TOOLTIP}>
        <PrimaryButton
          type="primary"
          block
          onClick={handleClick}
          shape="round"
          icon={<EditOutlined />}
        >
          ASSIGN TASK
        </PrimaryButton>
      </Tooltip>
      <TodoModal
        isModalVisible={isModalVisible}
        onCancel={closeModal}
        selectedDoc={selectedDoc}
      />
    </>
  );
};
export default AddTodoAction;
