import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

const { Footer } = Layout;

const StyledFooter = styled(Footer)`
  text-align: center;
  background-color: ${({ theme: { colors } }) => colors.transparent};
  font-size: 12px;
`;

const TelecareFooter = () => (
  <StyledFooter>Copyright © 2020 telecareonline.com.au</StyledFooter>
);

export default TelecareFooter;
