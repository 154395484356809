import React, { useEffect, useState } from 'react';
import { Alert, Checkbox, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { loginFirebase } from '../../../tools/login';
import PrimaryButton from '../../../components/PrimaryButton';
import { LoginForm } from './styles';
import { EXTERNAL_LINKS, STATUS } from '../../../constant';
import { Link, useHistory } from 'react-router-dom';
import { HOME } from '../../../constant/routes';
import { setLoginError, setLoginStatus } from '../../../slices/userSlice';

const { LOADING, FAILED, SUCCESSFUL } = STATUS;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const { loginStatus, loginError } = user;

  const [consent, setConsent] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);

  const handleLogin = async (value) => {
    dispatch(setLoginStatus(LOADING));
    setRememberMe(value.remember ?? true);
    const { status } = await loginFirebase(value);

    // when user login with wrong username & psw, which will not cause the auth state change so can not be covered in authStateChange callback function
    if (status === FAILED) {
      dispatch(setLoginStatus(FAILED));
      dispatch(
        setLoginError({
          message: 'Sorry, the email or password is incorrect.',
        })
      );
    }
  };

  useEffect(() => {
    if (loginStatus === SUCCESSFUL) {
      localStorage.setItem('rememberMe', rememberMe);
      history.push(HOME);
    }
  }, [loginStatus]);

  return (
    <LoginForm
      name="login"
      initialValues={{ remember: true }}
      onFinish={handleLogin}
    >
      <Form.Item
        name="userName"
        rules={[
          {
            required: true,
            message: 'The email can not be empty.',
          },
        ]}
      >
        <Input placeholder="Email" size="large" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'The password can not be empty.' }]}
      >
        <Input type="password" placeholder="Password" size="large" />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked" noStyle>
        <Checkbox className="login-form-checkbox">Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Checkbox
          className="login-form-checkbox"
          checked={consent}
          onChange={(e) => setConsent(e.target.checked)}
        >
          I accept Telecare’s{' '}
          <a
            href={EXTERNAL_LINKS.TERMS_OF_USE}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use{' '}
          </a>
          and{' '}
          <a
            href={EXTERNAL_LINKS.PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <PrimaryButton
          disabled={!consent}
          type="primary"
          htmlType="submit"
          shape="round"
          size="large"
          block
          loading={LOADING === loginStatus}
        >
          Sign In
        </PrimaryButton>
      </Form.Item>
      <Link
        style={{ display: 'block', textAlign: 'center', paddingTop: '28px' }}
        to="/reset-password"
      >
        Forgot your password?
      </Link>

      {loginStatus === FAILED && loginError && (
        <Alert
          message={`${loginError.message}`}
          type="error"
          showIcon
          className="login-error-tip"
        />
      )}
    </LoginForm>
  );
};

export default Login;
