import React, { useMemo, useState } from 'react';
import { Button, Col, Input, Layout, Spin, Typography } from 'antd';
import { DocumentListContainer, StyledCard, StyledContent } from './styles';
import { useSelector } from 'react-redux';
import useQuery from '../../hooks/useQuery';
import {
  MEDICAL_RESULT_FILES,
  PRACTITIONER_MEDICAL_RESULTS,
} from '../../constant/apis';
import { findDocsByDocId } from './tools';
import { STATUS } from '../../constant';
import DocumentList from './components/DocumentList';
import FilePreviewer from '../../components/FilePreviewer';
import DetailsCard from './components/DetailsCard';
import { NoDocument } from './components/NoDocument';
import moment from 'moment/moment';
import DismissAllAction from './components/DismissAllAction';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Search } = Input;
const Home = () => {
  const { coreplusId: practitionerId } = useSelector(({ user }) => user);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const {
    data,
    status,
    error,
    refetch: refetchPractitionerMedicalResults,
  } = useQuery(
    `${PRACTITIONER_MEDICAL_RESULTS}?practitionerId=${practitionerId}`,
    practitionerId
  );
  const [nameFilter, setNameFilter] = useState('');

  const documentList = useMemo(() => {
    const documents =
      data?.map((document) => {
        return {
          ...document,
          createdDate: document.createdDate._seconds,
        };
      }) ?? [];
    if (Boolean(nameFilter)) {
      return documents.filter((doc) =>
        (doc.clientName?.toLocaleLowerCase() || '').includes(
          nameFilter.toLocaleLowerCase()
        )
      );
    }
    return documents;
  }, [data, nameFilter]);

  const selectedDoc = useMemo(
    () => findDocsByDocId(documentList, selectedDocId),
    [documentList, selectedDocId]
  );

  const fileURL = useMemo(() => {
    if (selectedDoc?.fileId) {
      return `${process.env.REACT_APP_CLOUD_FUNCTIONS}${MEDICAL_RESULT_FILES}/${selectedDoc.fileId}`;
    }

    return '';
  }, [selectedDoc?.fileId]);
  const [showLeft, setShowLeft] = useState(true);
  const [showRight, setShowRight] = useState(true);

  if (status === STATUS.FAILED) {
    console.error({
      event: 'fetchPractitionerMedicalResultsFailed',
      error,
    });

    throw {
      message: 'Ops, can not get any practitioner medical results.',
    };
  }

  if (status === STATUS.LOADING || status === STATUS.DEFAULT) {
    return (
      <div
        style={{
          margin: '20px 0',
          marginBottom: '20px',
          padding: '30px 50px',
          textAlign: 'center',
          borderRadius: '4px',
        }}
      >
        <Spin />
      </div>
    );
  }
  const leftWidth = showLeft ? 6 : 1;
  const rightWidth = showRight ? 6 : 1;
  const midWidth = 24 - leftWidth - rightWidth;

  return (
    <Layout>
      <StyledContent>
        <Col span={leftWidth}>
          <StyledCard>
            {showLeft ? (
              <DocumentListContainer>
                <Button
                  onClick={() => setShowLeft(false)}
                  icon={<LeftOutlined />}
                  type="ghost"
                  size="small"
                  shape="circle"
                  style={{
                    padding: '6px',
                    alignSelf: 'flex-end',
                  }}
                />
                <Search
                  placeholder="type the patient name to search"
                  onSearch={(name) => setNameFilter(name)}
                  allowClear
                  style={{
                    padding: '35px 35px 0',
                    width: '100%',
                  }}
                />
                <DismissAllAction
                  specialistId={practitionerId}
                  refetchPractitionerMedicalResults={
                    refetchPractitionerMedicalResults
                  }
                />
                <DocumentList
                  documentList={documentList.sort((documentA, documentB) => {
                    const clientNameA = documentA.clientName
                      ? documentA.clientName
                      : '';
                    const clientNameB = documentB.clientName
                      ? documentB.clientName
                      : '';
                    return (
                      clientNameA.localeCompare(clientNameB) ||
                      moment.unix(documentA.createdDate) -
                        moment.unix(documentB.createdDate)
                    );
                  })}
                  selectedDocId={selectedDocId}
                  setSelectedDocId={setSelectedDocId}
                  refetchPractitionerMedicalResults={
                    refetchPractitionerMedicalResults
                  }
                />
              </DocumentListContainer>
            ) : (
              <Button
                onClick={() => setShowLeft(true)}
                icon={<RightOutlined />}
                type="ghost"
                size="small"
                shape="circle"
              />
            )}
          </StyledCard>
        </Col>
        <Col span={midWidth}>
          <Typography.Title style={{ textAlign: 'center' }} level={5}>
            CLINICAL CORRESPONDENCE
          </Typography.Title>
          {selectedDoc?.fileId ? (
            <FilePreviewer fileURL={fileURL} />
          ) : (
            <Typography.Text>No Document is selected</Typography.Text>
          )}
        </Col>
        <Col span={rightWidth}>
          <StyledCard>
            {showRight ? (
              <>
                <Button
                  onClick={() => setShowRight(false)}
                  icon={<RightOutlined />}
                  type="ghost"
                  size="small"
                  shape="circle"
                />
                {selectedDoc ? (
                  <DetailsCard
                    selectedDoc={selectedDoc}
                    fileURL={fileURL}
                    refetchPractitionerMedicalResults={
                      refetchPractitionerMedicalResults
                    }
                    practitionerId={practitionerId}
                  />
                ) : (
                  <NoDocument />
                )}
              </>
            ) : (
              <Button
                onClick={() => setShowRight(true)}
                icon={<LeftOutlined />}
                type="ghost"
                size="small"
                shape="circle"
              />
            )}
          </StyledCard>
        </Col>
      </StyledContent>
    </Layout>
  );
};

export default Home;
