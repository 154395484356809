import styled from 'styled-components';
import { Form } from 'antd';

export const LoginForm = styled(Form)`
  & .login-error-tip {
    margin-top: 20px;
  }

  & .ant-checkbox-wrapper-checked.login-form-checkbox .ant-checkbox-inner {
    background-color: #00a2e0;
  }
`;
